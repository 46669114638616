import React from 'react';


function BlogHeader() {
    return (
        <div className="blog-header">
            <h1>Blog</h1>
            <h2>My half-baked ruminations</h2>
        </div>
    );
}

export default BlogHeader;