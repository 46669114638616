import React from 'react';
// import CaliPicture from "../../assets/pictures/rodin_picture.png";
import Picture from "../../assets/pictures/professional_2023.jpg"

function HomePicture() {
    return (
        <img className="home-picture" src={Picture} alt="professional.png"></img>
    );
}

export default HomePicture;