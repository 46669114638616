import React from 'react';

function Footer() {
    return (
        <footer>
            Built and designed by <span style={{ color: "#1D1D1D" }}>Glen Liu</span>
        </footer>
    );
}

export default Footer;